<template>
    <div @mouseenter="store.getComponentCursor($event, null)" class="mission-vision innerContainer">
        <div class="up">
            <div class="content editor">
                <h2 class="header luxury">{{ item.title }}</h2>
                <div class="title fira" v-html="item.content"></div>
            </div>
            <div class="image-container">
                <picture class="picture">
                    <source
                        media="(max-width:767px)"
                        :srcset="item.mobile1"
                    />
                    <source
                        media="(max-width:1023px)"
                        :srcset="item.tablet1"
                    />
                    <img class="slide-image"
                         :src="item.desktop1"
                    />
                </picture>
            </div>
        </div>
        <div class="image-container down-image-container"
             data-scroll
             data-scroll-speed="3"
        >
            <picture>
                <source
                    media="(max-width:767px)"
                    :srcset="item.mobile2"
                />
                <source
                    media="(max-width:1023px)"
                    :srcset="item.tablet2"
                />
                <img class="down-image"
                     :src="item.desktop2"
                />
            </picture>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import Swiper from "swiper";
import {useMenuStore} from "../../../store/pinia/menu";

const props = defineProps({
    contentData: {
        type: Object,
    },
});
const store = useMenuStore();

let item = computed(() => {
    let item = props.contentData.data?.list?.[0]
    return {
        ...item,
        mobile1: item?.images?.[0]?.devices?.mobile,
        desktop1: item?.images?.[0]?.devices?.desktop,
        tablet1: item?.images?.[0]?.devices?.tablet,
        mobile2: item?.images?.[1]?.devices?.mobile,
        desktop2: item?.images?.[1]?.devices?.desktop,
        tablet2: item?.images?.[1]?.devices?.tablet,
    }
})

</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);

.mission-vision {
    color: $primaryNavy;
    margin-top: 89px;
    @media only screen and (max-width: 1439px) and (min-width: 1365px) {
        width: calc(100% - 390px) !important;
    }
    @media only screen and (max-width: 1364px) and (min-width: 1024px) {
        width: calc(100% - 50px) !important;
    }

    .up {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 1023px) {
            flex-direction: column;
        }

        .content:deep {
            width: calc(50% - 20px);
            @media only screen and (max-width: 1023px) {
                width: 100%;
            }

            .header {
                font-weight: 400;
                font-size: 55px;
                text-transform: uppercase;
                @media only screen and (max-width: 1439px) {
                    font-size: 34px;
                }
            }

            .title {
                display: block;
                margin-top: 21px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;

                h1, h2 {
                    font-family: 'Luxury', 'BesarionCaps';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 55px;
                    text-transform: uppercase;
                    @media only screen and (max-width: 1439px) {
                        font-size: 34px;
                    }
                }

                p, span {
                    font-family: 'FiraGO';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }

        .picture {
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media only screen and (max-width: 1023px) {
                width: 100%;
                height: 350px;
                margin-top: 21px;
            }
        }
    }

    .down-image-container {
        z-index: 1;
        margin-top: -40px;
        max-height: 700px;
        width: 60% !important;
        @media only screen and (max-width: 1023px) {
            display: none;
        }
    }

    .down-image {
        width: 100%;
        object-fit: cover;
    }

    img {
        transition: all .45s $ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    .image-container {
        overflow: hidden;
        width: calc(50% - 20px);
        @media only screen and (max-width: 1023px) {
            width: 100%;
        }
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .mission-vision {
            color: white;
        }
    }
}

</style>
